<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">Info Progress Pekerjaan</h4> <select style="margin-left:15px;width:100px" @change="loadData" v-model="tahun" class="form-control-sm float-left form-control"><option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option></select> 

          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table class="table table-bordered" id="datatable">
          <thead>
            <tr>
              <th rowspan="2">#</th>
              <th rowspan="2">Sub Kegiatan / Pekerjaan</th>
              <th rowspan="2">Nilai Kontrak</th>
              <th colspan="3">Progress</th>
              <th rowspan="2">Deviasi</th>
              <th rowspan="2">Foto</th>
              <th rowspan="2">Laporan</th>
            </tr>
            <tr>
              <th>Rencana Fisik Komulatif Minggu Ini</th>
              <th>Realisasi Fisik Komulatif Minggu Ini</th>
              <th>Realisasi Keuangan sd Minggu Ini</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
    <!-- all props & events -->
    <CModal color="success" title="Sukses" :show.sync="myFoto">
      <img :src="img" style='width:100%' />
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 



export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            myFoto : false,
            img : '',
            user : JSON.parse(localStorage.getItem('user')),
            tahun: new Date().getFullYear(),
            imgs: '',  // Img Url , string or Array of string
            visible: false,
            index: 0   // default: 0
        }
  },
  methods: {
    showSingle(img) {
      this.img = img
      this.myFoto = true;
    },
    loadData: function() {
      
      let username = this.user.id;
      console.log(this.user);
      let id_kontraktor = this.user.id_kontraktor;
      if (this.tahun == null){
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": "https://sippd.probowsolution.com/sippd/api/ajax_progress_pekerjaan.php?action=table_data&k="+id_kontraktor,
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "nama_pekerjaan" },
                  { "data": "nilai_kontrak" }, 
                  { "data": "rencana_fisik" },
                  { "data": "realisasi_fisik" },
                  { "data": "nilai_realisasi" },
                  { "data": "deviasi_fisik" },
                  { "data": "foto" },
                  { "data": "laporan" },
              ]  

          });
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
              "bDestroy": true ,
               "pageLength": 50,
              "ajax":{
                       "url": "https://sippd.probowsolution.com/api/ajax_progress_pekerjaan.php?action=table_data&tahun="+this.tahun+"&k="+id_kontraktor,
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "nama_pekerjaan" },
                  { "data": "nilai_kontrak" },
                  { "data": "rencana_fisik" },
                  { "data": "realisasi_fisik" },
                  { "data": "nilai_realisasi" },
                  { "data": "deviasi_fisik" },
                  { "data": "foto" },
                  { "data": "laporan" },
              ]  
          
 
          });
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>